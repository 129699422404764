<template>
  <b-row>
    <b-col>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div class="mb-3">
          <div class="pull-right">
            <b-form-select
              v-model="query.per_page"
              :options="[5, 10, 25, 100]"
              v-if="items != undefined && items.length > 0"
            >
            </b-form-select>
          </div>
          <b-row>
            <b-col class="col-md-4">
              <b-form @submit.stop.prevent="submitSearch">
                <b-input-group>
                  <b-input-group-prepend is-text
                    ><i class="fa fa-search"></i
                  ></b-input-group-prepend>
                  <b-form-input
                    v-model="search"
                    placeholder="Хайх"
                  ></b-form-input>
                  <b-input-group-append
                    ><b-button variant="primary" type="submit"
                      >Хайх</b-button
                    ></b-input-group-append
                  >
                  <b-input-group-append
                    ><b-button variant="secondary" @click="loadData"
                      ><i class="fa fa-refresh"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
          </b-row>
        </div>
        <div
          class="table-responsive"
          v-if="items != undefined && items.length > 0"
        >
          <b-table
            id="filter-table"
            stacked="md"
            @sort-changed="sortingChanged"
            hover
            bordered
            small
            responsive="sm"
            :items="items"
            :fields="columns"
            no-local-sorting
          >
            <template v-slot:cell(index)="data">
              <span style="width: 20px;">{{ data.index + pagination.from }}</span>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button-group
                v-if="data.index <= pagination.to - pagination.from"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  :key="data.index"
                  :href="data.item.contractFile"
                  target="blank"
                >Гэрээ
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  :key="data.index"
                  :to="{
                    name: 'admin.customer.detail',
                    params: { id: data.item.customerId }
                  }"
                  target="blank"
                >
                <i class="fa fa-user"></i>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
          <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > query.per_page">
              <b-pagination
                class="mb-0"
                :total-rows="pagination.total"
                :per-page="query.per_page"
                v-model="query.page"
              />
            </nav>
            <p class="mb-0">
              {{ pagination.total }} өгөгдлөөс
              {{ pagination.to ? pagination.from : 0 }} -
              {{ pagination.to }} өгөгдөл харагдаж байна.
            </p>
          </div>
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "admin.contract.file",
  data: function() {
    return {
      isLoading: false,
      filterShow: false,
      query: {
        page: 1,
        per_page: 10,
        search: "",
        orderDirection: "DESC",
        orderBy: "createdDate",
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      items: [],
      search: "",
      columns: [
        {label: '№', key: "index", class: "number_width text-center"},
        {
          label: "Овог",
          key: "customerLastname",
          sortable: true,
          sort_key: "customerLastname"
        },
        {
          label: "Нэр",
          key: "customerFirstname",
          sortable: true,
          sort_key: "customerFirstname"
        },
        {
          label: "Регистр",
          key: "customerRegisterNumber"
        },
        {
          label: "Гэрээний дугаар",
          key: "contractNumber",
          sortable: true,
          sort_key: "contractNumber"
        },
        {
          label: "Бүртгэсэн ажилтан",
          key: "registeredUserEmail"
        },
        {
          label: "Бүртгэсэн огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "createdDate",
        },
        { label: "Үйлдэл", key: "actions", class: "text-center" },
      ],
    };
  },
  created: function() {
    if (!this.isEmpty(this.$route.query)) {
      this.$data.query = this.$route.query;
    } else {
      this.$data.query.page = 1;
      this.$data.query.per_page = 10;
      this.$data.query.search = "";
    }
    this.loadData();
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true,
    },
    'query.search': {
      handler(search) {
        this.$data.query.page = 1;
      },
      deep: true,
    },
    'query.per_page': {
      handler(per_page) {
        this.$data.query.page = 1;
      },
      deep: true,
    }
  },
  methods: {
    loadData: function() {
      console.log('init')
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_digital_contracts", {
          headers: this.$store.getters.httpHeader,
          params: {
            page: this.$data.query.page || 1,
            per_page: this.$data.query.per_page || 10,
            search: this.$data.query.search || '',
            orderDirection: this.$data.query.orderDirection || '',
            orderBy: this.$data.query.orderBy || '',
          },
          emulateJSON: true,
        })
        .then(
          (response) => {
            console.log(response)
            this.isLoading = false;
            this.$data.items = response.body.list;
            this.$data.pagination.total = response.body.total;
            this.$data.pagination.from = response.body.from;
            this.$data.pagination.to = response.body.to;
          },
        );
        this.$router.push({ name: this.$route.name, query: this.$data.query });
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
    },
    submitSearch: function() {
      this.$data.query.search = this.$data.search;
      this.$data.page = 1;
    },
    async fetchData() {
      if (this.isLoading) {
        this.isLoading = true;
        const response = await this.$http.get(
          this.$config.API_URL + "CustomerWebService/get_digital_contracts",
          {
            params: {
              page: 1,
              per_page: 0,
              search: this.$data.query.search,
              orderDirection: this.$data.query.orderDirection || '',
              orderBy: this.$data.query.orderBy || '',
            },
            emulateJSON: true,
          }
        );
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.isLoading = false;
          return [];
        }

        let _result = [];
        for (let i in response.data.list) {
          let _item = response.data.list[i];
          _item.index = parseInt(i) + 1;
          
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.isLoading = true;
    },
    finishDownload() {
      this.isLoading = false;
    },
  },
};
</script>
